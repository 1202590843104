import React, { Component } from 'react';
import {
  Col, Card, Tooltip,
} from 'antd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import moment from 'moment';

import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

class GatheringSelection extends Component {

  constructor() {
    super();
    this.state = {
      stateMedia: false,
    };
    this.onChangeMedia = this.onChangeMedia.bind(this);
  }

  componentDidMount() {
    const media = window.matchMedia(`(min-width: 992px)`);
    media.addEventListener('change', this.onChangeMedia);
  }

  onChangeMedia = (e) => {
    const newStateMediaQuery = e.matches;
    this.setState({ stateMedia: newStateMediaQuery })
  }

  render() {
    const { t, gathering, gatherings, activeKey, selectionKey, activeGathering, memberMinistryNames } = this.props;
    const { stateMedia } = this.state;

    return (
      <Col
        xs={24} sm={24} md={24} lg={8}
        style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
      >
        <Tooltip
          title={"Please select a gathering"}
          visible={_.isEmpty(activeGathering) && (selectionKey === "1" || stateMedia)}
        >
        <button
          style={{ border: "none", display: "flex", justifyContent: "center" }}
          className={activeKey === selectionKey ? "card-wrapper card-wrapper-checked" : "card-wrapper"}
          onClick={() => {
            this.props.setActiveKey(selectionKey);
            this.props.setActiveGathering(gathering);
            if(!_.isEmpty(memberMinistryNames)
              && memberMinistryNames.length === 1
              && constants.gatheringWithDuties.indexOf(gathering.name) >= 0
            ) {
                this.setState({ is_modal_visible: true });
            }
          }}
        >
          <Card
            size="small"
            className={activeKey === selectionKey ? "card-wrapper mobile card-wrapper-checked" : "card-wrapper mobile"}
          >
            <div
              style={
                activeKey === selectionKey ?
                { fontSize: '1em', textAlign: "center" }
                :
                { fontSize: '1em', color: '#08c', textAlign: "center" }
              }
            >
              <div>
                {
                  `${moment(gathering.startDateTime).format("MMM.DD (ddd)")} ${moment(gathering.startDateTime).format("h:mmA")} ${gathering.name ? t(gatherings[gathering.name]) : ""}`
                }
              </div>
            </div>
          </Card>
          <Card
            className={activeKey === selectionKey ? "card-wrapper non-mobile card-wrapper-checked" : "card-wrapper non-mobile"}
          >
            <div
              style={
                activeKey === selectionKey ?
                { fontSize: '2em', textAlign: "center" }
                :
                { fontSize: '2em', color: '#08c', textAlign: "center" }
              }
            >
              <div>
                {
                  `${moment(gathering.startDateTime).format("MMM.DD (ddd)")}`
                }
              </div>
              <div>
                {
                  `${moment(gathering.startDateTime).format("h:mmA")}`
                }
              </div>
              <div>
                {
                  `${gathering.name ? t(gatherings[gathering.name]) : ""}`
                }
              </div>
            </div>
          </Card>
        </button>
        </Tooltip>
      </Col>
    );
  }
}

export default withTranslation()(GatheringSelection);