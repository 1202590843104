import { Badge, Button, Calendar, Col, Form, Icon, Input, Modal, Radio, Row, Select, Table, Tag, Tooltip } from 'antd';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { withRouter } from "react-router";

import _ from 'lodash';
import moment from 'moment';
import { stringify } from 'query-string';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import amsAPI from '../../apis/amsAPI';
import kapiAPI from '../../apis/kapiAPI';

import 'antd/dist/antd.css';
import './Redirect.css';

const guestColumns = [
  {
    title: 'No',
    dataIndex: 'key',
    key: 'key',
    render: key => <span>{key}</span>,
  },
  {
    title: 'Religion',
    dataIndex: 'religion',
    key: 'religion',
    render: religion => <span>{religion}</span>,
  },
  {
    title: 'Count',
    dataIndex: 'count',
    key: 'count',
    render: count => <span>{count}</span>,
  },
];

class AttendanceReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };
  state = {
    members: [],
    gatheringTypes: [],
    kapi_remittances: [],
    linkUrl: "",
    clickedMemberName: '',
    gatheringInfo: {},
    loadingGatheringInfo: true,
    loading: false,
    isModalVisible: false,
  }

  componentDidMount() {
    this.getRequiredInfoFromAPI();
    const { activeGathering, linkInfo, attendanceInfo } = this.props;
    const { gatheringLink } = activeGathering;
    const { localeLinks } = linkInfo;
    const { localeChurch } = attendanceInfo;

    let linkUrl = "";
    if (localeLinks && localeLinks[localeChurch]) {
      linkUrl = localeLinks[localeChurch];
    } else {
      linkUrl = gatheringLink;
    }
    this.setState({ linkUrl });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    const memberIds = this.props.checkedMembers;
    const { activeGathering } = this.props;
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data }))
      .catch(err => console.log(err));
    if (!_.isEmpty(activeGathering)) {
      this.setState({ loadingGatheringInfo: true })
      this.callApi(`/ams/gatherings/${activeGathering._id}?populate=1`)
        .then(res => this.setState({ gatheringInfo: res.data, loadingGatheringInfo: false }))
        .catch(err => console.log(err));
    }
    this.getMembers(memberIds)
      .then(res => {
        let { members } = res;
        this.setState({ members, loadingMembers: false });
      })
      .catch(err => console.log(err));
  }

  getKapiRemittances = async (query) => {
    this.setState({ loading: true });
    const queryWithStatus = {
      ...query,
      status: this.props.status,
    };
    const response = await kapiAPI.getUrl(`/kapi/remittances?${stringify(queryWithStatus)}`)
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getMembers = async (ids) => {
    const query = {
      filter: JSON.stringify({ id: ids }),
    };
    const response = await amsAPI.getUrl(`/ams/members?${stringify(query)}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await amsAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  returnHome = async (e) => {
    e.preventDefault();
    this.props.history.push("/");
  };

  clickAccessLink = async (e) => {
    e.preventDefault();
    const { members } = this.state;
    const { activeGathering } = this.props;
    amsAPI.fetchUrl(`/ams/gatherings/${activeGathering._id}/link_accesses`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        member: members[0]._id,
        gathering: activeGathering._id,
        action: 'click link',
      }),
    })
    .then(async res => {
      window.location.href = activeGathering.gatheringLink;
    })
    .catch(err => {
      console.log('link update error');
    });
  };

  copyAccessLink = async () => {
    const { members } = this.state;
    const { activeGathering } = this.props;
    amsAPI.fetchUrl(`/ams/gatherings/${activeGathering._id}/link_accesses`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        member: members[0]._id,
        gathering: activeGathering._id,
        action: 'copy link',
      }),
    })
    .then(async res => {
      console.log('copied')
    })
    .catch(err => {
      console.log('link update error');
    });
  };

  createTableColumns = async () => {
    this.columns = [
      {
        title: 'No',
        dataIndex: 'key',
        key: 'key',
        render: key => <span>{key}</span>,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: name => <span>{name}</span>,
      },
      {
        title: 'KAPI Inbox',
        dataIndex: 'isKapiAssociate',
        key: 'isKapiAssociate',
        hidden: true,
        render: (text, record) => (
          <span>
            {record.isKapiAssociate ?
              <Button onClick={async () => {
                this.getKapiRemittances({ userMemberId: record._id, memberId: record._id })
                  .then(res => {
                    this.setState({
                      kapi_remittances: res.data,
                      loading: false,
                      isModalVisible: true,
                      clickedMemberName: record.name,
                    })
                  })
              }}>
                <Badge dot>
                  <Icon type="mail" />
                </Badge>
              </Button>
              :
              null
            }
          </span>
        )
      },
    ].filter(item => !item.hidden);;
  }

  handleClose = () => {
    this.setState({ isModalVisible: false });
  };

  getMonthData = (value) => {
    let is_remitted = false;
    let { kapi_remittances} = this.state;
    for (let i = 0; i < kapi_remittances.length; i++) {
      let item = kapi_remittances[i];
      if (value.isSame(item.month, 'month')) {
        is_remitted = true;
        break;
      }
    }
    return is_remitted;
  }

  monthFullCellRender = (value) => {
    const is_remitted = this.getMonthData(value);
    return is_remitted ? (
      <div className="notes-month">
        <Button type="dashed">
          {value.format('MMM')}
        </Button>
        <Tag color="#87d068">
          <Icon type="check-circle" />
        </Tag>
      </div>
    ) : (
      <div className="notes-month">
        <Button type="dashed">
          {value.format('MMM')}
        </Button>
        <Tag color="lime">
          <Icon type="close-circle" />
        </Tag>
      </div>
    );
  }

  render() {
    this.createTableColumns();
    const { receiptNumber, activeGathering, channel, otherChannel, linkInfo, attendanceInfo } = this.props;
    const { members, linkUrl, gatheringTypes, gatheringInfo, loadingGatheringInfo, isModalVisible, clickedMemberName } = this.state;
    const { assignedServants } = gatheringInfo;
    const { submissionDateTime, guestAttendance, gathering } = attendanceInfo;
    const { startDateTime } = activeGathering;
    const formatedGatheringDate = moment(startDateTime).format("MMM.DD(ddd), h:mm A");
    const formatedSubmission = moment(submissionDateTime).format("h:mm A");
    const gatherings = {};
    gatheringTypes.forEach(item => {
      gatherings[item.code] = item.name;
    })

    let modResult = [];
    members.forEach((item, index) => {
      modResult.push({ ...item, key: ++index });
    });

    let modGuestAttendance = [];
    let i = 0;
    guestAttendance.forEach(item => {
      if (item.religion) modGuestAttendance.push({ ...item, key: ++i });
    });

    return (
      <div className="wrap">
        <div className="extraContent">
          {receiptNumber ?
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Your attendance has been successfully submitted.</h3>
                    <span>Your attendance receipt number is:</span>
                    <h2>{receiptNumber}</h2>
                  </div>
                  <Modal
                    visible={isModalVisible}
                    maskClosable={false}
                    title={`KAPI Remittance for ${clickedMemberName}`}
                    onOk={this.handleClose}
                    onCancel={this.handleClose}
                    footer={[
                      <Button key="submit" type="primary" onClick={this.handleClose}>
                        Close
                      </Button>,
                    ]}
                  >
                    <Calendar
                      mode="year"
                      fullscreen={false}
                      monthFullCellRender={this.monthFullCellRender}
                      headerRender={({ value, type, onChange, onTypeChange }) => {
                        const year = moment().year();
                        console.log('year', year)
                        const options = [];
                        for (let i = year - 10; i < year; i += 1) {
                          options.push(
                            <Select.Option key={i} value={i} className="year-item">
                              {i}
                            </Select.Option>,
                          );
                        }
                        console.log('options', options)
                        return (
                          <div style={{ padding: 10 }}>
                            <div style={{ marginBottom: '10px' }}>Custom header </div>
                            <Row type="flex" justify="space-between">
                              <Col>
                                <Radio.Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                                  <Radio.Button value="year">Year</Radio.Button>
                                </Radio.Group>
                              </Col>
                              <Col>
                                <Select
                                  size="small"
                                  dropdownMatchSelectWidth={false}
                                  className="my-year-select"
                                  onChange={newYear => {
                                    const now = value.clone().year(newYear);
                                    onChange(now);
                                    this.getKapiRemittances({
                                      userMemberId: members[0]._id,
                                      memberId: members[0]._id,
                                      year: newYear
                                    })
                                  }}
                                  value={String(year)}
                                >
                                  {options}
                                </Select>
                              </Col>
                            </Row>
                          </div>
                        );
                      }}
                    />
                  </Modal>
                </Col>
              </Row>
              {(channel === "zoom" && linkInfo.linkId && linkInfo.url) &&
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                  <div>
                    <h3>Please access the stream via the following link:</h3>
                    <div><Button type="link" onClick={this.clickAccessLink}>{linkInfo.url}</Button></div>
                  </div>
                </Col>
              </Row>
              }
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24}>
                  <div>
                    <h4>These were the information submitted:</h4>
                    <ul>
                      <li>Date:&nbsp;
                        <strong>{`${formatedGatheringDate}`}</strong>
                      </li>
                      <li>Gathering:&nbsp;
                        <strong>{`${gatherings[activeGathering.name]} (${activeGathering.type})`}</strong>
                      </li>
                      {!loadingGatheringInfo &&
                        <li>Assigned Servant/s:&nbsp;<br/>
                          {assignedServants.map(item => {
                            return <div key={item.name}><strong key={item.name}>{item.name}</strong></div>;
                          })}
                        </li>
                      }
                      <li>Channel:&nbsp;
                        <strong>{channel.toUpperCase()} {otherChannel ? `(${otherChannel.toUpperCase()})` : ""}</strong>
                      </li>
                      {(channel === "zoom" && gathering && gathering.meetingId && gathering.passcode) ?
                          <ul>
                            <li>Meeting ID:&nbsp;<strong>{gathering.meetingId}</strong></li>
                            <li>Passcode:&nbsp;<strong>{gathering.passcode}</strong></li>
                          </ul>
                        : null
                      }
                      <li>Submission:&nbsp;
                      <strong>{`${formatedSubmission}`}</strong>
                      </li>
                    </ul>
                    {(channel === "zoom" && !_.isEmpty(linkUrl)) &&
                      <div>
                        <h4>Please access the gathering through the following link:</h4>
                        <span><a href={linkUrl} onClick={this.clickAccessLink}>Open Link</a></span>
                        <Form.Item label="OR">
                          <Input
                            value={linkUrl}
                            disabled={true}
                          />
                          <CopyToClipboard text={linkUrl}>
                            <Tooltip title="Copied!" trigger="click">
                              <Button onClick={this.copyAccessLink}>
                                <Icon type="copy"/>Copy Link
                              </Button>
                            </Tooltip>
                          </CopyToClipboard>
                        </Form.Item>
                      </div>
                    }
                    <h4>Attendees:</h4>
                    <Table pagination={false} columns={this.columns} dataSource={modResult} />
                    {attendanceInfo.hasGuests &&
                      <div>
                        <h4>Guest/s:</h4>
                        <Table pagination={false} columns={guestColumns} dataSource={modGuestAttendance} />
                      </div>
                    }
                  </div>
                </Col>
              </Row>
            </div>
            :
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} style={{ textAlign: "center" }}>
                <div><Button onClick={this.returnHome}>Return to home</Button></div>
              </Col>
            </Row>
          }
        </div>
      </div>
    );
  }
}

export default withRouter(AttendanceReceipt);
