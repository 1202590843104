import {
  Button, Card, Col, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Switch, Tooltip
} from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';

import ActiveGathering from './ActiveGathering';
import GatheringSelection from './GatheringSelection';

import emmetAPI from '../../emmetAPI';
import * as constants from '../../helpers/constants';

import 'antd/dist/antd.css';
import './Home.css';

const Option = Select.Option;

let timeout;
let currentValue;

function fetch(value, callback) {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  async function fake() {
    let searchParams = new URLSearchParams({
      q: value,
    }).toString();
    emmetAPI.getUrl(`/ams/members?${searchParams}`)
      .then(async response => {
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        return body;
      })
      .then(d => {
        if (currentValue === value) {
          callback(d.members);
        }
      });
  }

  timeout = setTimeout(fake, 300);
}

function modalError() {
  Modal.error({
    title: 'Attendance Submission Error',
    content: 'Sorry, but your attendance has already been submitted.',
  });
}

class ReligionInput extends Component {
  state = {
    isReligionInputted: false,
    isCountInputted: false,
  }
  render() {
    const { attendanceInfo, index, isOtherInput } = this.props;
    const { guestAttendance } = attendanceInfo;
    const { isReligionInputted, isCountInputted } = this.state;

    return (
      <div>
        <Form.Item
          validateStatus={isCountInputted && !isReligionInputted ? "error" : ""}
          help={isCountInputted && !isReligionInputted ? "Please input religion" : ""}
        >
          {isOtherInput ?
            <Input
              style={{ width: "250px" }}
              onChange={e => {
                const religion = e.target.value;
                guestAttendance[index] = { count: guestAttendance[index].count, religion }
                this.props.setAttendanceInfo({ guestAttendance });

                const isReligionInputted = !_.isEmpty(religion);
                this.setState({ isReligionInputted });

                const hasError = (isReligionInputted && !isCountInputted);
                this.props.setGuestInputError(hasError);
              }}
            />
            :
            <Select
              onChange={selected => {
                guestAttendance[index] = { count: guestAttendance[index].count, religion: selected }
                this.props.setAttendanceInfo({ guestAttendance });
                this.props.handleReligionSelect(selected);

                const isReligionInputted = !_.isEmpty(selected);
                this.setState({ isReligionInputted });

                const hasError = (isReligionInputted && !isCountInputted);
                this.props.setGuestInputError(hasError);
              }}
              style={{ width: "250px" }}
              allowClear={true}
            >
              {constants.religions.map(item => {
                return (
                  <Option key={item} value={item}>{item}</Option>
                )
              })}
            </Select>
          }
        </Form.Item>
        <Form.Item
          validateStatus={isReligionInputted && !isCountInputted ? "error" : ""}
          help={isReligionInputted && !isCountInputted ? "Please input count" : ""}
        >
          <InputNumber
            min={1}
            onChange={value => {
              guestAttendance[index] = { religion: guestAttendance[index].religion, count: value };
              this.props.setAttendanceInfo({ guestAttendance });

              const isCountInputted = value > 0;
              this.setState({ isCountInputted });

              const hasError = (isCountInputted && !isReligionInputted);
              this.props.setGuestInputError(hasError);
            }}
          />
        </Form.Item>
      </div>
    );
  }
}

class ClientHome extends Component {
  state = {
    mode: '',
    activeKey: '',
    selectedGathering: '',
    memberMinistryNames: [],
    members: [],
    churchLocales: [],
    gatheringTypes: [],
    loadingGathering: true,
    submitting: false,
    hasGuestInputError: false,
    isPerformingDuty: false,
    membersInfo: [],
    memberAttendances: [],
  };

  componentDidMount() {
    this.props.setAttendanceInfo({});
    this.getRequiredInfoFromAPI();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.getRequiredInfoFromAPI();
    }
  }

  getRequiredInfoFromAPI = async () => {
    this.setState({ loadingGathering: true });
    this.callApi(`/ams/gathering_types`)
      .then(res => this.setState({ gatheringTypes: res.data, loading: false }))
      .catch(err => console.log(err));
    this.getActiveGatherings()
      .then(res => {
        this.setState({ loadingGathering: false });
        if (res.gatherings.length === 1) {
          this.props.setActiveGathering(res.gatherings[0]);
        } else {
          this.props.setActiveGatherings(res.gatherings);
        }
      })
      .catch(err => console.log(err));
  }

  getActiveGatherings = async () => {
    const response = await emmetAPI.getUrl(`/ams/gatherings?active=true`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  callApi = async (url) => {
    this.setState({ loading: true });
    const response = await emmetAPI.getUrl(url);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleSubmitAttendance = async (e) => {
    ReactGA.event({
      category: 'Button Click',
      action: 'submit attendance'
    });

    e.preventDefault();
    this.setState({ submitting: true });
    const memberIds = this.props.checkedMembers.map(item => item)
    const { t, activeGathering, channel, otherChannel, attendanceInfo } = this.props;
    const { passkey, isPerformingDuty, memberAttendances } = this.state;
    const { guestAttendance } = attendanceInfo;

    const modGuestAttendance = [];
    guestAttendance.forEach(item => {
      if (item.religion) modGuestAttendance.push(item);
    })
    emmetAPI.fetchUrl(`/ams/member_attendance?gatheringId=${activeGathering._id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        memberIds: memberIds,
        channel: channel,
        otherChannel: otherChannel.toLowerCase(),
        passkey: passkey,
        guestAttendance: modGuestAttendance,
        isPerformingDuty: isPerformingDuty,
        memberAttendances: memberAttendances,
        weekNum: activeGathering.weekNum,
      }),
    })
    .then(async res => {
      if (res.status === 200) {
        const response = await res.json();
        if (!response.error_id) {
          message.success('Attendance successfully submitted.');
          const { receiptNumber, link, linkId, submissionDateTime, localeChurch } = response.result[0];
          this.props.setReceiptNumber(receiptNumber);
          this.props.setLinkInfo({ url: link, linkId, localeLinks: response.localeLinks });
          this.props.setAttendanceInfo({ receiptNumber, submissionDateTime, localeChurch, gathering: response.gathering });
          this.setState({ submitting: false });
          this.props.history.push(`/attendance_receipt`);
        } else if (response.error_id === 'ERR001') {
          this.setState({ submitting: false });
          message.error('Wrong pass key.');
        } else if (response.error_id === 'ERR002') {
          this.setState({ submitting: false });
          message.error('All gathering links have been allocated. Please contact your assigned worker.');
        } else if (!_.isEmpty(response.error_id)) {
          this.setState({ submitting: false });
          message.error(t(response.error_id));
          this.getRequiredInfoFromAPI();
        }
      } else if (res.status === 422) {
        this.setState({ submitting: false, mode: '' });
        this.props.clearState();
        //message.error(t('Attendance already submitted.'));
        modalError();
      } else {
        const error = new Error(res.error);
        throw error;
      }
    })
    .catch(err => {
      console.error(err);
      this.setState({ submitting: false });
      message.error(t('Error submitting attendance.'));
    });
  };

  handleSearch = value => {
    if (value) {
      fetch(value, data => {
        this.setState({ members: data })
      });
    } else {
      this.setState({ members: [] });
    }
  };

  handleChange = selectedMembers => {
    this.props.setMembers(selectedMembers)
    this.setState({ selectedMembers })
    if (!_.isEmpty(selectedMembers)) {
      this.getMembers(selectedMembers)
      .then(res => {
        this.setState({ membersInfo: res.data, loadingMember: false });
        const membersInfo = res.data;
        const membersWithMinistries = membersInfo.filter(item => !_.isEmpty(item.ministries));

        if (!_.isEmpty(membersWithMinistries) && membersWithMinistries.length === 1) {
          let memberMinistryNames = membersWithMinistries[0].ministries.map(i => i.name);
          this.setState({ memberMinistryNames });

          let { activeGathering } = this.props;
          let isWithDutyGathering = constants.gatheringWithDuties.indexOf(activeGathering.name) >= 0;
          if(!_.isEmpty(memberMinistryNames)
            && memberMinistryNames.length === 1
            && isWithDutyGathering
          ) {
            this.setState({ is_modal_visible: true });
          }
        } else if (!_.isEmpty(membersWithMinistries) && membersWithMinistries.length > 1) {
          this.setState({ multi_on_duty_form: true });
        } else {
          this.setState({ memberMinistryNames: [] });
        }

      })
      .catch(err => console.log(err));
    } else {
      this.setState({ memberMinistryNames: [] });
    }
  };

  getMembers = async (selectedMembers) => {
    const response = await emmetAPI.getUrl(`/ams/members?memberIds=${selectedMembers}`);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleReligionSelect = selectedReligions => {
    this.props.setAttendanceInfo(selectedReligions)
  };

  setGuestInputError = value => {
    this.setState({ hasGuestInputError: value });
  }

  handleNo = () => {
    this.setState({ isPerformingDuty: false, is_modal_visible: false });
  };

  handleYes = () => {
    this.setState({ isPerformingDuty: true, is_modal_visible: false });
  };

  render() {
    const {
      members, memberMinistryNames, loadingGathering, hasGuestInputError, gatheringTypes,
      is_modal_visible, modal_loading, isPerformingDuty, membersInfo, selectedMembers,
    } = this.state;
    const { t, checkedMembers, activeGathering, activeGatherings, channel, otherChannel, attendanceInfo } = this.props;
    const gatherings = {};
    gatheringTypes.forEach(item => {
      gatherings[item.code] = item.name;
    })

    const membersWithMinistries = membersInfo ? membersInfo.filter(item => !_.isEmpty(item.ministries)) : [];

    if (loadingGathering) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={12} style={{ textAlign: "center" }}>
                <Spin size="large" />
              </Col>
            </Row>
          </div>
        </div>
      )
    }

    if (_.isEmpty(activeGathering) && _.isEmpty(activeGatherings)) {
      return (
        <div className="wrap">
          <div className="extraContent">
            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={18}>
                <Card style={{display: "flex", justifyContent: "center"}}>
                  <div style={{ fontSize: '1.5em', color: '#08c', textAlign: "center", whiteSpace: "wrap" }}>
                    {t("Sorry, but attendance submission is currently closed.")}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )
    }
    let channels = activeGathering.channels || [];
    const disableSubmit = _.isEmpty(checkedMembers) || _.isEmpty(activeGathering) || !channel || (channel === "others" && !otherChannel) || hasGuestInputError;
    let isWithDutyGathering = constants.gatheringWithDuties.indexOf(activeGathering.name) >= 0;

    return (
      <div className="wrap">
        <div className="extraContent">
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h2>Attendance Submission</h2>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              {_.isEmpty(activeGatherings) ?
                <ActiveGathering activeGathering={activeGathering} gatherings={gatherings} />
                :
                <Row type="flex" justify="center">
                  <GatheringSelection
                    selectionKey="1"
                    activeKey={this.state.activeKey}
                    activeGathering={activeGathering}
                    gathering={activeGatherings[0]}
                    gatherings={gatherings}
                    setActiveKey={(activeKey) => this.setState({ activeKey: activeKey })}
                    setActiveGathering={this.props.setActiveGathering}
                    memberMinistryNames={memberMinistryNames}
                  />
                  <GatheringSelection
                    selectionKey="2"
                    activeKey={this.state.activeKey}
                    activeGathering={activeGathering}
                    gathering={activeGatherings[1]}
                    gatherings={gatherings}
                    setActiveKey={(activeKey) => this.setState({ activeKey: activeKey })}
                    setActiveGathering={this.props.setActiveGathering}
                    memberMinistryNames={memberMinistryNames}
                  />
                  {activeGatherings[2] ? 
                    <GatheringSelection
                      selectionKey="3"
                      activeKey={this.state.activeKey}
                      activeGathering={activeGathering}
                      gathering={activeGatherings[2]}
                      gatherings={gatherings}
                      setActiveKey={(activeKey) => this.setState({ activeKey: activeKey })}
                      setActiveGathering={this.props.setActiveGathering}
                      memberMinistryNames={memberMinistryNames}
                    />
                  :
                  null
                  }
                </Row>
              }
              <Form>
                {membersWithMinistries.length > 1 ?
                  <Form.Item label={t("Please select those who attended")}>
                    {selectedMembers.map(item => {
                      return (
                        <Select
                          showSearch
                          placeholder={t("Input member name or id")}
                          dropdownMatchSelectWidth={false}
                          mode={this.state.mode}
                          optionFilterProp="value"
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={false}
                          notFoundContent={null}
                          value={item}
                          key={item}
                        >
                          {members.map(item => {
                            return (
                              <Option key={item._id} value={item._id}>
                                {`${item.name}`}
                              </Option>
                            )
                          })}
                        </Select>
                      )
                    })}
                  </Form.Item>
                :
                  <Form.Item label={t("Please select those who attended")}>
                    <Select
                      showSearch
                      placeholder={t("Input member name or id")}
                      dropdownMatchSelectWidth={false}
                      mode={this.state.mode}
                      optionFilterProp="value"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={this.handleSearch}
                      onChange={this.handleChange}
                      notFoundContent={null}
                      value={checkedMembers}
                      allowClear={true}
                    >
                      {members.map(item => {
                        return (
                          <Option key={item._id} value={item._id}>
                            {`${item.name}`}
                          </Option>
                        )
                      })}
                    </Select>
                    <Modal
                      visible={is_modal_visible}
                      maskClosable={false}
                      title="On-Duty Attendance"
                      onOk={this.handleYes}
                      onCancel={this.handleNo}
                      footer={[
                        <Button key="back" onClick={this.handleNo}>
                          No
                        </Button>,
                        <Button key="submit" type="primary" loading={modal_loading} onClick={this.handleYes}>
                          Yes
                        </Button>,
                      ]}
                    >
                      <strong>{t(`Are you performing your duty?`)}</strong>
                    </Modal>
                    {(!_.isEmpty(memberMinistryNames) && isWithDutyGathering) ?
                      <div>
                        {t(`Are you performing your duty?`)}
                        <Switch
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                          checked={isPerformingDuty}
                          onChange={(checked) => {
                            this.setState({ isPerformingDuty: checked })
                          }}
                          style={{ marginLeft: 5 }}
                        />
                      </div>
                      :
                      null
                    }
                  </Form.Item>
                }
                {t("Are other brethren attending with you?")}
                <Tooltip
                  title={<span>Please select their names in the box above</span>}
                  placement="bottom"
                  visible={
                    this.state.mode === "multiple" && _.isEmpty(checkedMembers) ? true : false
                  }
                >
                  <Switch
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                    onChange={(checked) => {
                      this.setState({ mode: checked ? 'multiple' : '' })
                    }}
                    style={{ marginLeft: 5 }}
                  />
                </Tooltip>
                <Form.Item label={t("Attended through")}>
                  <Select
                    placeholder={t("Hook-up channel")}
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => {
                      this.props.setChannel(value);
                      this.props.setOtherChannel('');
                    }}
                    value={channel}
                    defaultValue={channel}
                    allowClear={true}
                  >
                    {channels.map(name =>
                      <Option key={name} value={name}>{name.charAt(0).toUpperCase() + name.slice(1)}</Option>
                    )}
                  </Select>
                </Form.Item>
                {channel === "others" &&
                  <Form.Item label={t("Other Channel")}>
                    <Input
                      value={otherChannel}
                      onChange={e => this.props.setOtherChannel(e.target.value)}
                    />
                  </Form.Item>
                }
              </Form>
            </Col>
          </Row>
          {(process.env.REACT_APP_DISABLE_GUEST === "true" || process.env.REACT_APP_DISABLE_GUEST === true) ?
            null
            :
            <div>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={18}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <h3>Guest Attendance Information</h3>
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={18}>
                  <Form>
                    <Form.Item>
                      {t("Do you have guests attending with you?")}
                      <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={(checked) => {
                          this.props.setAttendanceInfo({ hasGuests: checked });
                        }}
                        style={{ marginLeft: 5 }}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              {attendanceInfo.hasGuests &&
                <div>
                  <Row type="flex" justify="center">
                    <Col xs={24} sm={24} md={24} lg={18}>
                      <Form layout="inline">
                        {_.range(5).map(i => {
                          return (
                            <ReligionInput
                              attendanceInfo={attendanceInfo}
                              setAttendanceInfo={this.props.setAttendanceInfo}
                              handleReligionSelect={this.handleReligionSelect}
                              index={i}
                              setGuestInputError={this.setGuestInputError}
                              isOtherInput={i === 4}
                            />
                          )
                        })}
                      </Form>
                    </Col>
                  </Row>
                </div>
              }
            </div>
          }
          <Row type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={18}>
              <Tooltip
                placement="topRight"
                title={"Please click me"}
                visible={!disableSubmit}
              >
                <Button block type="primary"
                  disabled={disableSubmit}
                  loading={this.state.submitting}
                  onClick={this.handleSubmitAttendance}
                >
                  {t("Click to Submit")}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withTranslation()(ClientHome);